import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Button, Alert } from 'react-bootstrap';

import { client } from 'api/auth';

function LoginForm({ login }) {
  const [forgot, setForgot] = useState(false);
  const [error, setError] = useState(null);

  return (
    <>
      <Alert
        variant="danger"
        onClick={() => setError(null)}
        className="bad-login-alert"
        style={{ visibility: error ? 'visible' : 'hidden' }}
        dismissible
      >
        {error}
      </Alert>
      <div className="h-25" />

      {
        forgot ?
          <ForgotPage backToLogin={() => setForgot(false)} onError={setError} /> :
          <LoginPage onForgot={() => setForgot(true)} afterLogin={login} onError={setError} />
      }
    </>
  );
}

function LoginPage({ afterLogin, onForgot, onError }) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();

    client.post('login/', { email, password }, { baseURL: '/auth' })
      .then((response) => afterLogin(response.data.key))
      .catch(() => onError('Incorrect email/password combination.'));
  };

  return (
    <Form onSubmit={handleSubmit} className="login-form">
      <div className="title">Login</div>

      <Form.Group controlId="Email">
        <Form.Label className="login-label">Email</Form.Label>
        <Form.Control
          autoFocus
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </Form.Group>
      <Form.Group controlId="Password">
        <Form.Label className="login-label">Password</Form.Label>
        <Form.Control
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
      </Form.Group>

      <Form.Row className="mt-3 mx-0">
        <Button variant="light" type="submit" className="font-weight-bold">Login</Button>
        <Button className="py-2 px-3 ml-1 text-white" variant="link" onClick={onForgot}>
          Forgot Password?
        </Button>
      </Form.Row>
    </Form>
  );
}

function ForgotPage({ backToLogin, onError }) {
  const [email, setEmail] = useState('');
  const [ isEmailSent, setIsEmailSent ] = useState(false);
  
  const handleSubmit = (e) => {
    e.preventDefault();

    client.post('password/reset/', { email }, { baseURL: '/auth' })
      .then(() => setIsEmailSent(true))
      .catch(() => onError('User not found.'));
  };

  if (isEmailSent) {
    return (
      <div  className="forgot-form">
        <div className="title">Check your inbox…</div>
        <p className="message">
          Help is on the way.<br/><br/>
          If there’s an associated account with the email you provided you will receive a password reset link.
        </p>

        <Button className="py-2 ml-1 text-white" variant="link" onClick={backToLogin}>
          Back to login
        </Button>
      </div>
    );
  }

  return (
    <Form onSubmit={handleSubmit} className="forgot-form">
      <div className="title">Forgot your password?</div>
      <p className="message">
        No worries, let’s fix that!<br/><br/>
        Enter the email address associated with your account, and we’ll send you instructions to reset your password.
      </p>

      <Form.Group controlId="Email">
        <Form.Label className="login-label">Email</Form.Label>
        <Form.Control
          autoFocus
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </Form.Group>

      <Form.Row className="mt-3 mx-0">
        <Button variant="light" type="submit" className="font-weight-bold">Reset</Button>
        <Button className="py-2 px-3 ml-1 text-white" variant="link" onClick={backToLogin}>Cancel</Button>
      </Form.Row>
    </Form>
  );
}

LoginPage.propTypes = {
  afterLogin: PropTypes.func.isRequired,
  onForgot: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
};

ForgotPage.propTypes = {
  backToLogin: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
};

LoginForm.propTypes = {
  login: PropTypes.func.isRequired,
};

export default LoginForm;
