import React, { useEffect, useState, useContext } from 'react';
import { Row, Col, Form, InputGroup, Button, ButtonGroup } from 'react-bootstrap';
import { Trash } from 'react-bootstrap-icons';

import { AuthContext } from 'api/context';
import Save from 'components/Save';
import ValidationForm from 'components/Form/ValidationForm';

const Adders = () => {
  const { newEndpoint, displaySnack } = useContext(AuthContext);
  const endpoint = newEndpoint('hardware/adders/');

  const [adders, setAdders] = useState([]);
  const [newAdder, setNewAdder] = useState();

  // componentDidMount
  useEffect(() => {
    endpoint.list()
      .then((data) => setAdders(data))
      .catch((e) => console.log(e));
  }, []);

  const handleUpdateField = (value, field, index) => {
    const updated = Array.from(adders);
    updated[index][field] = value;
    setAdders(updated);
  };

  const handleUpdate = (adder) => {
    endpoint.update(adder.id, adder)
      .then(() => displaySnack({ variant: 'success', message: `Updated ${adder.name}.` }))
      .catch(() => displaySnack({ variant: 'danger', message: `Failed update ${adder.name}.` }));
  };

  const handleDelete = (index) => {
    const updated = Array.from(adders);
    const rm = updated.splice(index, 1)[0];
    endpoint.remove(rm.id)
      .then(() => { setAdders(updated); displaySnack({ variant: 'success', message: `Deleted ${rm.id}.` }); })
      .catch(() => displaySnack({ variant: 'warning', message: `Failed to delete ${rm.name}` }));
  };

  const handleCreate = () => {
    // send POST request for new adder
    endpoint.create(newAdder)
      .then(() => endpoint.list().then((data) => setAdders(data)))
      .then(() => {
        displaySnack({ variant: 'success', message: `Added ${newAdder.name}.` });
        setNewAdder();
      })
      .catch(() => displaySnack({
        variant: 'danger',
        message: 'Failed to add new adder.',
      }));
  };

  return (
    <>
      <Row className="font-weight-bold mt-2">
        <Col sm={2}>Adder</Col>
        <Col sm={2}>Cost</Col>
        <Col sm={2}>Cost Type</Col>
        <Col sm={2}>Rebate Exempt</Col>
        <Col sm={2}>Hidden</Col>
      </Row>

      {adders.map((item, index) => (
        <Row key={item.id} className="mt-1">
          <Col sm={2}>{item.name}</Col>
          <Col sm={2}>
            <InputGroup>
              <InputGroup.Prepend>
                <InputGroup.Text>$</InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                value={item.cost}
                onChange={(e) => handleUpdateField(e.target.value, 'cost', index)}
              />
            </InputGroup>
          </Col>
          <Col sm={2} className="my-auto">
            <Form.Check
              inline
              id={`flat_cost_${index}`}
              type="radio"
              label="Flat"
              checked={item.flat_cost}
              onChange={() => handleUpdateField(true, 'flat_cost', index)}
            />
            <Form.Check
              inline
              id={`watt_cost_${index}`}
              type="radio"
              label="Per Watt"
              checked={!item.flat_cost}
              onChange={() => handleUpdateField(false, 'flat_cost', index)}
            />
          </Col>
          <Col sm={2} className="my-auto">
            <Form.Check
              type="checkbox"
              checked={item.rebate_dq}
              onChange={(e) => {
                if (!item.hidden) {
                  handleUpdateField(e.target.checked, 'rebate_dq', index)
                } else {
                  displaySnack({ variant: 'warning', message: `Hidden adders can not be rebate exempt!` })
                }
              }}
            />
          </Col>
          <Col sm={2} className="my-auto">
            <Form.Check
              type="checkbox"
              checked={item.hidden}
              onChange={(e) => {
                if (!item.rebate_dq) {
                  handleUpdateField(e.target.checked, 'hidden', index);
                } else {
                  displaySnack({ variant: 'warning', message: `Rebate exempt adders can not be hidden!` })
                }
              }}
            />
          </Col>
          <Col sm={2}>
            <ButtonGroup size="sm">
              <Button variant="outline-primary p-2" onClick={() => handleUpdate(item)}>
                <Save />
              </Button>
              <Button variant="outline-primary p-2" onClick={() => handleDelete(index)}>
                <Trash />
              </Button>
            </ButtonGroup>
          </Col>
        </Row>
      ))}

      {/* New Adder */}
      {newAdder === undefined ? (
        <Row className="mt-4">
          <Col>
            <Button onClick={() => setNewAdder({ flat_cost: false, rebate_dq: false, hidden: true })}>
              + Adder
            </Button>
          </Col>
        </Row>
      ) : (
        <ValidationForm onSave={handleCreate}>
          <Row className="mt-1">
            <Col sm={2}>
              <Form.Control
                required
                value={newAdder.name || ''}
                onChange={(e) => setNewAdder({ ...newAdder, name: e.target.value })}
              />
            </Col>
            <Col sm={2}>
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text>$</InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control
                  type="number"
                  value={newAdder.cost || ''}
                  onChange={(e) => setNewAdder({ ...newAdder, cost: e.target.value })}
                />
              </InputGroup>
            </Col>
            <Col sm={2} className="my-auto">
              <Form.Check
                inline
                id="new_flat_cost"
                type="radio"
                label="Flat"
                checked={newAdder.flat_cost}
                onChange={() => setNewAdder({ ...newAdder, flat_cost: true })}
              />
              <Form.Check
                inline
                id="new_watt_cost"
                type="radio"
                label="Per Watt"
                checked={!newAdder.flat_cost}
                onChange={() => setNewAdder({ ...newAdder, flat_cost: false })}
              />
            </Col>
            <Col sm={2} className="my-auto">
              <Form.Check
                  type="checkbox"
                  checked={newAdder.rebate_dq}
                  onChange={(e) => {
                    if (!newAdder.hidden) {
                      setNewAdder({ ...newAdder, rebate_dq: e.target.checked })
                    } else {
                      displaySnack({ variant: 'warning', message: `Hidden adder can not be rebate exempt!` })
                    }
                  }}
              />
              </Col>
            <Col sm={2} className="my-auto">
              <Form.Check
                  type="checkbox"
                  checked={newAdder.hidden}
                  onChange={(e) => {
                    if (!newAdder.rebate_dq) {
                      setNewAdder({ ...newAdder, hidden: e.target.checked });
                    } else {
                      displaySnack({ variant: 'warning', message: `Rebate exempt adder can not be hidden!` })
                    }
                  }}
              />
            </Col>
            <Col sm={1}>
              <Button variant="outline-primary p-2" size="sm" onClick={() => setNewAdder()}>
                <Trash />
              </Button>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col>
              <Button type="submit">Save</Button>
            </Col>
          </Row>
        </ValidationForm>
      )}
    </>
  );
};

export default Adders;
