import React, { useState } from 'react';
import { Col, Form, Button, Alert } from 'react-bootstrap';
import { useParams, useHistory } from 'react-router-dom';

import { client } from 'api/auth';

function ResetForm() {
  const [password, setPassword] = useState('');
  const [password2, setPassword2] = useState('');
  const [state, setState] = useState({ validated: false });

  const history = useHistory();
  const { token, uid } = useParams();

  const handleSubmit = (e) => {
    e.preventDefault();

    const form = e.currentTarget;

    if (form.checkValidity() === false) {
      e.stopPropagation();
      setState({ validated: true });
      return;
    }

    const data = { new_password1: password, new_password2: password2, uid, token };
    client.post('password/reset/confirm/', data, { baseURL: '/auth' })
      .then(() => history.push('/'))
      .catch((err) => setState({ ...state, error: err.response.data }));
  };

  return (
    <>
      <Alert
        variant="danger"
        onClick={() => setState({ ...state, error: null })}
        className="bad-login-alert"
        style={{ visibility: state.error ? 'visible' : 'hidden' }}
        dismissible
      >
        {state.error && (state.error.new_password2 || state.error.new_password1 || 'Unknown Error')}
      </Alert>
      <div className="h-25" />

      <Form noValidate validated={state.validated} onSubmit={handleSubmit} className="login-form">
        <div className="title">
          Password Reset
        </div>
        <Form.Group controlId="Password">
          <Form.Label className="login-label">Password</Form.Label>
          <Form.Control
            id="password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </Form.Group>
        <Form.Group controlId="Password">
          <Form.Label className="login-label">Confirm Password</Form.Label>
          <Form.Control
            id="password2"
            type="password"
            value={password2}
            className="login-input"
            onChange={(e) => setPassword2(e.target.value)}
            required
          />
        </Form.Group>
        <Form.Row className="pt-2 d-flex justify-content-between">
          <Col xs={12} md={6}>
            <Button
              variant="light"
              type="submit"
              className="w-100 px-5 font-weight-bold"
            >
              Submit
            </Button>
          </Col>
          <a className="py-2 px-3 text-white" href="/">
            Login
          </a>
        </Form.Row>
      </Form>
    </>
  );
}

export default ResetForm;
